import AppContext from '../../AppContext';

import './Page.css';

import { useContext, useState } from 'react';

function VoorWieNieuw() {
  const { globalState, updateGlobalState } = useContext(AppContext);
 
  return (
    <div id="voor-wie" className="waarom-section voorwie-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section-heading centered dark-heading">
              {/* <h6>Waarom trainen met een schema?</h6> */}
              <h2 className="mt-2 mb-4">Herken jij dit?</h2>
              <p>
              Heb je eerder geprobeerd je fitnessdoelen te bereiken, maar zie je niet de resultaten die je zou willen? Dit jaar kan het anders. Ik help je om eindelijk de resultaten te behalen die je verdient.
              </p>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-8 mx-auto">
            {/* <div className="voorwie-item">
              <p>
                <img src="https://selllikecrazybook.com/wp-content/themes/sell-like-crazy/assets/prod/images/red-bullet.png" alt="" />
              Sta jij uren in de sportschool, maar krijg je niet de resultaten die je zou verwachten?

              </p>
            </div> */}
            <div className="voorwie-item">
              <p>
                <img src="https://selllikecrazybook.com/wp-content/themes/sell-like-crazy/assets/prod/images/red-bullet.png" alt="" />
                <span>
                Je bent net begonnen of traint al regelmatig, maar ziet geen vooruitgang – wat doe je verkeerd?
                </span>
              </p>
            </div>
            <div className="voorwie-item">
              <p>
                <img src="https://selllikecrazybook.com/wp-content/themes/sell-like-crazy/assets/prod/images/red-bullet.png" alt="" />
                <span>
                Loop je elke keer de sportschool in zonder een duidelijk plan en kies je oefeningen op de gok?
                </span>
              </p>        
            </div>
            <div className="voorwie-item">
              <p>
                <img src="https://selllikecrazybook.com/wp-content/themes/sell-like-crazy/assets/prod/images/red-bullet.png" alt="" />
                <span>
                Je wordt overweldigd door tegenstrijdige informatie – en je hebt geen idee wat werkt.                </span>
              </p>        
            </div>
            <div className="voorwie-item">
              <p>
                <img src="https://selllikecrazybook.com/wp-content/themes/sell-like-crazy/assets/prod/images/red-bullet.png" alt="" />
                <span>
                Train je zonder structuur en houd je geen enkel resultaat bij, waardoor je geen idee hebt of je vooruitgang boekt?                </span>        
              </p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-7 mx-auto my-4">
            <p>
              <i>
                Het is niet jouw schuld. Zonder een duidelijk plan dat gemaakt is voor jouw lichaam is het bijna onmogelijk om écht vooruitgang te boeken. Gelukkig kan het anders.        
              </i>
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 d-flex justify-content-center">
            
            <a href="#offer" className="button button-red d-flex mt-4 justify-content-center">
            Ontdek hoe je jouw doelen bereikt.
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VoorWieNieuw;