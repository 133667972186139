import AppContext from '../../AppContext';

import Countdown, { zeroPad } from 'react-countdown';
import ReactPixel from 'react-facebook-pixel';

import './OfferPakket.css';
import { useContext, useState } from 'react';
import { useEffect } from 'react';

import { Routes, Route, Outlet, Link, redirect, useNavigate, useSearchParams } from "react-router-dom";

import axios from 'axios';
import PaymentIcons from './PaymentIcons';
import { Checkbox, Select, Box } from '@mantine/core';
import { useInViewport } from '@mantine/hooks';

// Import Swiper React components
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

ReactPixel.init('336340599280167', options);

function OfferCard({ defaultPrice }) {
  const { globalState, updateGlobalState } = useContext(AppContext);

  var today = new Date();
  today.setHours(today.getHours() + 7);
  today.setMinutes(today.getMinutes() + 23);
  today.setSeconds(today.getSeconds() + 12);

  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return false;
    } else {
      // Render a countdown
      return (
        <div className="timer" style={{ display: 'flex' }}>
          {/* <div className="timer-desc">
            <span style={{ whiteSpace: "nowrap" }}>ik zoek nog</span>
          </div> */}
          {/* <div className="timer-box">
            <span>2</span>
          </div> */}
          {/* <div className="timer-desc">
            <span>klanten</span>
          </div> */}
          <div className="timer-box">
            <span>{ zeroPad(days) }</span>
            <span>dagen</span>
          </div>
          <div className="timer-box">
            <span>{ zeroPad(hours) }</span>
            <span>uren</span>
          </div>
          <div className="timer-box">
            <span>{ zeroPad(minutes) }</span>
            <span>minuten</span>
          </div>
          <div className="timer-box">
            <span>{ zeroPad(seconds) }</span>
            <span>seconden</span>
          </div>
        </div>
      );
    }
  };

  const [loading, setLoading] = useState(false);
  
  const [searchParams, setSearchParams] = useSearchParams();
  const [paymentStatus, setPaymentStatus] = useState(null)

	const navigate = useNavigate();

  useEffect(function() {
    if (searchParams.get("paymentResponse")) {
      setPaymentStatus(searchParams.get("paymentResponse"));
    }
  }, [searchParams]);

  const [title, setTitle] = useState('Spiergroei Proefpakket');

  const createPayment = () => {
    setLoading(true);

    const values = {
      "message": "Payment",
      "title": title,
      "email": globalState.email, 
      "naam": globalState.naam,
      "amount": defaultPrice
    }

    // axios.post(`https://localhost:5000/api/stripe/`, values, {
    axios.post(`https://healthyliving-project.com/api/stripe/`, values, {
      headers: {
        'Accept': 'application/json'
      }
    })
    .then(function (response) {
      // handle success 
      console.log(response); 
      console.log(response.data.session.url);

      ReactPixel.trackCustom('Add To Cart', {currency: "EUR", value: defaultPrice});

      window.location.href = response.data.session.url;
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
    .finally(function () { 
      // always executed
      console.log('done'); 
      setLoading(false);
      
      // navigate(`/thank-you`);
    });
  }

  const [price, setPrice] = useState(defaultPrice);
  
  const { ref, inViewport } = useInViewport();

  const [stickySet, setStickySet] = useState(false);

  useEffect(() => {
    if (stickySet) return;

    if (inViewport) {
      setStickySet(true);
    }
  }, [inViewport])


  const images = [
    {
      img: "/img/offer/offer-gids.jpg",
      description: "Spiermassa Gids"
    },
    {
      img: "/img/offer/offer-schema.jpg",
      description: "Op maat gemaakt schema (voorbeeld)"
    },
    {
      img: "/img/offer/offer-workout.jpg",
      description: "Workouts bijhouden"
    },
    {
      img: "/img/offer/offer-logboek.jpg",
      description: "Logboek"
    },
    {
      img: "/img/offer/offer-app.jpg",
      description: "Eiwitten bijhouden"
    },
    // {
    //   img: "/img/offer/offer-recepten.jpg",
    //   description: "Receptenboek"
    // }
  ]
 
  return (
    <>
      <div id="offer" className="section pakket-section">
        <div className="container">
          {/* <div className="row">
            <div className="col-md-12">
              <h3>Unieke aanbieding 👇</h3>
            </div>
          </div> */}

          <div className="offer-sticker">
            <span>Profiteer van</span>
            <span>88%</span>
            <span>korting</span>
          </div>

          <div className="row mt-md-5 justify-content-center">
        
            {/* <div className="col-md-10 mx-auto">
              <h2>Ben je klaar voor de beste resultaten van je leven?</h2>
            </div> */}

            <div className="col-md-8 mx-auto">
              <div className="prijs-card">
                <h2 className="mb-4">Investeer vandaag nog in jezelf</h2>

                <p>
                Wil je even proeven hoe het is om getraind te worden door een online personal trainer? Dit <b>Spiergroei Proefpakket</b> is precies wat je nodig hebt! Ervaar de kracht van op maat gemaakte trainingsschema's en online begeleiding voor deze exclusief lage prijs. Wacht niet langer, begin vandaag nog.               
                </p>

                <h4 className="mt-5 text-xl" style={{ fontWeight: '500' }}>Het Spiergroei Proefpakket</h4>
                <h5 className="mt-3 mb-3 text-xl" style={{ fontWeight: '500' }}>Je ontvangt gedurende 28 dagen:</h5>
                
                <ul className="offer-simple-list">
                  <li>
                    <span>
                      <b>💪🏼 Een helder trainingsplan waarmee je je doelen bereikt zonder tijd te verspillen.</b>
                      <span style={{ textDecoration: 'line-through' }}>€59,99</span>
                    </span>
                    <p>
                      💪🏼 Op maat gemaakt voor jouw unieke wensen en behoeften. Geen standaard schema's, alleen wat voor jou werkt.
                    </p>
                  </li>
                  <li>
                    <span>
                      <b>💪🏼 Elke week nieuwe workouts met variatie en uitdaging, zodat je gemotiveerd blijft.</b>
                      <span style={{ textDecoration: 'line-through' }}>€24,99</span>
                    </span>
                    <p>
                      💪🏼 Altijd variatie en uitdaging in je trainingen, zodat je gemotiveerd blijft en je spieren maximaal prikkelt.
                    </p>
                  </li>
                  <li>
                    <span>
                      <b>💪🏼 We passen je plan wekelijks aan op jouw progressie, zodat je consistent blijft groeien en nooit stil blijft staan.</b>
                      <span style={{ textDecoration: 'line-through' }}>€24,99</span>
                    </span>
                    <p>
                      💪🏼 Wekelijkse aanpassingen gebaseerd op jouw resultaten
                    </p>
                  </li>
                  <li>
                    <span>
                      <b>💪🏼 Je hebt altijd een expert aan je zijde voor snelle antwoorden en directe begeleiding, zodat je nooit meer twijfelt over je aanpak.</b>
                      <span style={{ textDecoration: 'line-through' }}>€24,99</span>
                    </span>
                    <p>
                      💪🏼 Tijdens dit programma heb je de mogelijkheid om ten allen tijden contact op te nemen met je persoonlijke coach. 
                    </p>
                  </li>
                  <li>
                    <span>
                      <b>💪🏼 Altijd inzicht in je voortgang, zodat je gemotiveerd blijft.</b>
                      <span style={{ textDecoration: 'line-through' }}>€29,99</span>
                    </span>
                    <p>
                      💪🏼 Houd eenvoudig je workouts en voortgang bij tijdens het trainen met onze mobiele app.
                    </p>
                  </li>
                  {/* <li>
                    <span>
                    <b>Dagelijks tips & tricks, motivatie en advies in je mailbox</b>
                      <span style={{ textDecoration: 'line-through', opacity: '0' }}>€2,99</span>
                    </span>
                  </li> */}
                  {/* <li>
                    <span>
                      💪🏼 Supplementengids
                      <span style={{ textDecoration: 'line-through' }}>€4,99</span>
                    </span>
                    <p>
                    Leer welke supplementen echt werken en hoe je ze optimaal kunt gebruiken. Geen hype, alleen de feiten.
                    </p>
                  </li> */}
                </ul>
              
                <hr style={{ opacity: '0.2' }} />

                <div className="pricing-items d-flex flex-column" style={{ marginTop: '30px' }}>
                  <span>Totale Waarde van de Tools: <span style={{ textDecoration: 'line-through' }}>€164,95</span></span>
                  <span>Jouw Prijs Vandaag Voor 4 Weken: <br className="mobile" /><span className="offer-price" style={{ color: 'var(--red)' }}>€4,99 per week! (€{ defaultPrice.toString().replace('.', ',') })</span></span>
                </div>

                <div className="klarna-price mt-2">
                  <span>Je kunt zelfs in 3 termijnen van €{ defaultPrice == 19.99 ? `6,66` : `9,99` } betalen. Renteloos. Met <img style={{ width: '58px' }} src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/40/Klarna_Payment_Badge.svg/640px-Klarna_Payment_Badge.svg.png" alt="" /></span>
                </div>

                {/* <div className="pricing">
                  <span style={{ color: 'var(--red)' }}>€{ defaultPrice.toString().replace('.', ',') }</span>
                  <span className="old-price">{ defaultPrice == 29.99 ? `€64,99` : `€127,95` }</span>
                </div> */}

                {/* <div className="klarna-price">
                  <span>Je kunt zelfs in 3 termijnen van €{ defaultPrice == 29.99 ? `10,00` : `10,66` } betalen. Renteloos. Met <img style={{ width: '58px' }} src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/40/Klarna_Payment_Badge.svg/640px-Klarna_Payment_Badge.svg.png" alt="" /></span>
                </div> */}
              
                {/* <button className="button button-red mt-5" type="button" onClick={() => createPayment()}>
                  Doe Mee met de Challenge! - €{ defaultPrice.toString().replace('.', ',') } <span style={{ textDecoration: 'line-through'}}>{ defaultPrice == 29.99 ? `€64,99` : `€127,95` }</span>
                </button> */}

                {/* <Box
                  ref={ref} 
                > 
                  <p className="mt-5">
                    {/* Mis deze kans niet. Ben je niet tevreden? Dan krijg je je geld meteen terug, zonder vragen. */}
                    {/* <br/><br/> 
                    Maar wacht... Omdat het bijna zomer is geef ik het volgende er ook nog <i>tijdelijk</i> gratis bij 🤯🤯:
                  </p>
                </Box>                 */}

                {/* <div className="row">
                  <div className="col-md-12">
                    <div className="prijs-timer">
                      <div className="timer mt-5 mt-md-0">
                        <Countdown 
                          date={today} 
                          renderer={renderer}
                        />
                      </div>
                    </div>
                  </div>
                </div> */}
                
                <div ref={ref} className={`button-container text-center mt-4 ${stickySet ? 'sticky-button' : null}`} style={{ flexDirection: 'column' }}>
                  <button className="button button-red" type="button" onClick={() => createPayment()}>
                    Kickstart Jouw Spiergroei! - €{ defaultPrice.toString().replace('.', ',') } <span style={{ textDecoration: 'line-through'}}>{ defaultPrice == 19.99 ? `€164,95` : `€79,98` }</span><span style={{ fontSize: '13px', marginLeft: '6px' }}>€4,99 per week!</span>
                  </button>

                  <div className="pay-info mt-2">
                    <span className="betalen-desc d-flex mb-2 justify-content-center">
                      Veilig betalen met:
                    </span>
                    <PaymentIcons />
                  </div>

                  <small style={{ fontSize: '14px', color: "#000" }}>Ben je niet tevreden? Je ontvangt je geld terug zonder vragen!</small>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}

export default OfferCard;