import AppContext from '../../AppContext';

import './Page.css';

import { useContext, useState } from 'react';

function VoorWieNieuw() {
  const { globalState, updateGlobalState } = useContext(AppContext);
 
  return (
    <div id="voor-wie" className="waarom-section voorwie-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section-heading centered dark-heading">
              {/* <h6>Waarom trainen met een schema?</h6> */}
              <h2 className="mt-2 mb-4">Herken jij dit?</h2>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-8 mx-auto">
            {/* <div className="voorwie-item">
              <p>
                <img src="https://selllikecrazybook.com/wp-content/themes/sell-like-crazy/assets/prod/images/red-bullet.png" alt="" />
              Sta jij uren in de sportschool, maar krijg je niet de resultaten die je zou verwachten?

              </p>
            </div> */}
            <div className="voorwie-item">
              <p>
                <img src="https://selllikecrazybook.com/wp-content/themes/sell-like-crazy/assets/prod/images/red-bullet.png" alt="" />
                <span>
                Heb jij het gevoel dat je keihard traint, maar de resultaten uitblijven?
                </span>
              </p>
            </div>
            <div className="voorwie-item">
              <p>
                <img src="https://selllikecrazybook.com/wp-content/themes/sell-like-crazy/assets/prod/images/red-bullet.png" alt="" />
                <span>
                Loop je elke keer de sportschool in zonder een vast plan en kies je oefeningen op goed geluk?
                </span>
              </p>        
            </div>
            <div className="voorwie-item">
              <p>
                <img src="https://selllikecrazybook.com/wp-content/themes/sell-like-crazy/assets/prod/images/red-bullet.png" alt="" />
                <span>
                Twijfel je of je de juiste techniek gebruikt en ben je bang dat je niet het maximale uit je training haalt?                </span>
              </p>        
            </div>
            <div className="voorwie-item">
              <p>
                <img src="https://selllikecrazybook.com/wp-content/themes/sell-like-crazy/assets/prod/images/red-bullet.png" alt="" />
                <span>
                Train je zonder structuur en houd je geen enkel resultaat bij, waardoor je geen idee hebt of je vooruitgang boekt?                </span>        
              </p>
            </div>
            <div className="voorwie-item">
              <p>
                <img src="https://selllikecrazybook.com/wp-content/themes/sell-like-crazy/assets/prod/images/red-bullet.png" alt="" />
                <span>
                Je hebt een druk (sociaal) leven en hebt moeite om gemotiveerd te blijven.
                </span>        
              </p>
            </div>
          </div>
        </div>

        <div className="row bottom-text">
          <div className="col-md-7 mx-auto my-4">
            <p>
            Als je jezelf hierin herkent, dan is mijn methode precies wat jij nodig hebt!            
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 d-flex justify-content-center">
            
            <a href="#offer" className="button button-red d-flex mt-4 justify-content-center">
              Ik ben geïnteresseerd
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VoorWieNieuw;