import { createStyles, BackgroundImage, Container, Center, Text, Box, Title, Flex, Button, Modal, useMantineTheme, SimpleGrid, Grid, Image, TextInput, LoadingOverlay, Group, AspectRatio } from '@mantine/core';
import { AnimatedSlider } from './AnimatedSlider';


const useStyles = createStyles((theme) => ({
  container: {
    // minHeight: '-moz-available',
    // minHeight: '-webkit-fill-available',
    // minHeight: 'stretch',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    overflow: 'hidden',
    // backgroundImage: `url('/images/pages/plicart-bg.jpg')`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    background: '#221e1d',
    paddingBottom: '60px',
    '@media (min-width: 992px)': {
      height: 'calc(100vh - 20px)',
      width: '100%',
      marginBottom: '0 !important',
      paddingBottom: '0'
    },
    '@media (max-width: 991px)': {
      minHeight: 'calc(100vh - var(--navbarHeight) - var(--footerHeight) + 60px)',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      display: 'flex', 
      flexDirection: 'column',
      justifyContent: 'flex-start',
      paddingBottom: '0',
      marginBottom: '0 !important',
      minHeight: 'calc(100vh - var(--navbarHeight) - var(--footerHeight) + 60px)',
      backgroundImage: `url('/images/ntnk-cats-bg.png')`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      marginBottom: '0 !important',
      overflow: 'hidden'
    }
  },
  top: {
    '@media (max-width: 991px)': {
      padding: '45px 20px'
    }
  },
  title: {
    fontSize: '60px',
    lineHeight: '70px',
    letterSpacing: '-.02em',
    color: '#fff',
    margin: '10px 0 30px',
    fontWeight: '700',
    maxWidth: '535px',
    textAlign: 'left',
    textTransform: 'uppercase',

    
    fontSize: '38px',
    lineHeight: '45px',
    margin: '5px 0 15px',
    
    '@media (max-width: 1400px)': {
      fontSize: '38px',
      lineHeight: '45px',
      margin: '5px 0 15px',
      color: '#fff'
    },
    
    '@media (max-width: 991px)': {
      fontSize: '28px',
      lineHeight: '32px !important',
      margin: '5px 0 15px',
      color: '#fff'
    }
  },
  second_title: {
    fontSize: '28px',
    lineHeight: '45px',
    letterSpacing: '-.02em',
    color: '#999',
    margin: '10px 0 30px',
    fontWeight: '700',
    maxWidth: '535px',
    textAlign: 'left',
    
    '@media (max-width: 1400px)': {
      fontSize: '28px',
      lineHeight: '45px',
      margin: '5px 0 15px',
      color: '#999',
    },
    
    '@media (max-width: 991px)': {
      fontSize: '24px',
      lineHeight: '45px',
      margin: '5px 0 15px',
      color: '#999',
    }
  },
  modal_title: {
    fontSize: '46px',
    marginRight: 'auto',
    lineHeight: '50px',
    letterSpacing: '-.02em',
    color: '#0b0b0b',
    margin: '10px 0 30px',
    fontWeight: '700',
    textAlign: 'left',
    
    '@media (max-width: 991px)': {
      fontSize: '38px',
      lineHeight: '45px',
      margin: '5px 0 15px'
    }
  },
  modal: {
    height: "800px",
    padding: "0",
    '.mantine-Modal-inner': {
      padding: '0'
    },
    '.mantine-Modal-modal': {
      padding: '0',
      backgroundColor: 'transparent',
      boxShadow: 'none'
    },

    
    '@media (min-width: 992px)': {
      height: "100vh"
    },

    
    '@media (max-width: 991px)': {
      height: "100dvh",
      padding: "0",
      '.mantine-Modal-modal': {
        padding: '0',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        height: "70vh",
        top: 'calc(50% - 70px)',
        transform: 'translateY(-50%) scale(1) !important'
      },
      '.mantine-Modal-header': {
        height: '0px',
      }
    }
  },
  flexContainer: {
    // minHeight: '-moz-available',
    // minHeight: '-webkit-fill-available',
    // minHeight: 'stretch',
    padding: '0 60px',
    margin: '0 !important',
    marginTop: '-60px',
    textAlign: 'left',
    '@media (max-width: 991px)': {
      maxWidth: '100%',
      height: '100%',
      marginBottom: '30px',
      marginTop: '0',
      position: 'relative',
      zIndex: '5',
      padding: '10px 0 0',
      display: 'flex',
      flexDirection: 'column-reverse',
      justifyContent: 'flex-start !important'
    }
  },
  modalFlexContainer: {
    // minHeight: '-moz-available',
    // minHeight: '-webkit-fill-available',
    // minHeight: 'stretch',
    padding: '0 60px',
    margin: '0 !important',
    marginTop: '-60px',
    textAlign: 'center',
    '@media (max-width: 991px)': {
      maxWidth: '100%',
      height: '100%',
      marginBottom: '30px',
      marginTop: '0',
      position: 'relative',
      zIndex: '5',
      padding: '10px 0 0',
      display: 'flex',
      flexDirection: 'column-reverse',
      justifyContent: 'flex-start !important'
    }
  },
  modal_content: {
    textAlign: 'left'
  },
  mobile_cta: {
    '@media (max-width: 991px)': {
      position: 'fixed',
      bottom: 'var(--footerHeight)',
      zIndex: '9999999999',
      width: '100vw',
      textAlign: 'center',
      opacity: 1,
      left: '0',
      transition: 'opacity .5s ease',
      maxWidth: 'none',
      borderRadius: '0 !important',
      'a': {
        width: '100%'
      },
      'button': {
        maxWidth: 'none !important',
        width: '100%',
        position: 'relative',
        'svg': {
          position: 'absolute',
          right: '30px',
          top: '50%',
          transform: 'translateY(-50%)'
        }
      },
      '&.active': {
        opacity: 1,
        transition: 'opacity .5s ease'
      }
    }
  },
  modal_container: {
    '.mantine-Modal-modal': {
      background: '#FAFAFA'
    },
    '@media (max-width: 991px)': {
      '.mantine-Modal-header': {
        marginBottom: '0px',
        position: 'absolute',
        right: '30px',
        top: '20px',
        zIndex: '10'
      }
    }
  },


  
  input: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    '.mantine-Input-wrapper': {
      width: '100%'
    },
    'input': {
      fontSize: '16px !important',
      border: '1px solid #EFEFEF',
      background: '#FFF'
    }
  },

  outer: {
    overflow: 'hidden'
  },

  swiper_outer: {
    marginBottom: '30px',
    '.swiper': {
      left: '0'
    }
  },

  swiper: {
    '@media (max-width: 991px)': {
      position: 'absolute',
      zIndex: '-1',
      opacity: '0.5',
      height: '100%'
    }
  },

  form_container: {
    '@media (max-width: 991px)': {
      margin: '0 10px'
    }
  },


  
  dropzone_container: {
    height: '100%',
    maxHeight: '165px',
    minHeight: '165px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  dropzone_outer: {
    '.mantine-Image-figure': {
      width: '100%', 
    },
    'figure': {
      height: '100%'
    },
    'img': {
      width: '100%', 
      objectFit: 'cover',
      maxHeight: '165px',
      minHeight: '165px',
      height: '165px',
    }
  },
  dropzone_button: {
    borderRadius: '80px',
    border: '1px solid #CFCFCF',
    padding: '15px 45px',
    fontSize: '16px !important'
  },
  aspect_ratio: {
    position: 'relative',
    borderRadius: '12px',
    overflow: 'hidden',
    'figure': {
      width: '100% !important' 
    }
  }

}));

const HeaderReview = () => {
  
  const { classes } = useStyles();

  const allTestimonials = [
    "https://via.placeholder.com/800x800",
    "https://via.placeholder.com/800x800",
    "https://via.placeholder.com/800x800",
    "https://via.placeholder.com/800x800",
    "https://via.placeholder.com/800x800",
    "https://via.placeholder.com/800x800",
    "https://via.placeholder.com/800x800",
    "https://via.placeholder.com/800x800",
    "https://via.placeholder.com/800x800",
    "https://via.placeholder.com/800x800",
    "https://via.placeholder.com/800x800",
    "https://via.placeholder.com/800x800",
    // {
    //   "_id": "656c4e35839ca227875d5190",
    //   "name": "Yaya",
    //   "title": "Yoga & Food",
    //   "feedback": "NTNK has been pivotal in my growth, teaching me the true value of a supportive network and aligning passion with fulfillment.",
    //   "avatar_url": "https://res.cloudinary.com/dby5950eg/image/upload/v1701596718/dnz6f8fb4hobk2yg5xf1.png",
    //   "status": "accepted"
    // }
  ]

  return (
    <Container className={classes.container} fluid style={{ marginBottom: '30px' }}>
      <Grid mt="0" className="header-review">
        <Flex 
          align="center"
          className={classes.flexContainer}
        >
          <Grid.Col sm={12} md={5.5} className={classes.top} mx="auto">
            <Flex 
              direction="column"
              align="start"
              pb="xl"
            >
              <Image mb="xl" src="/img/hlp-logo-wit.svg" height={50} width={208} alt="NTNK logo" />
              <Text fz={{ base: 'lg' }} fw={600} tt="uppercase" mb={{ base: 'lg', md: '0' }} c={'var(--red)'}>
              Gegarandeerde resultaten!
              </Text>
              <Title className={classes.title} order={1} mb="md">
                Bouw Zichtbare Spieren en Kracht in Slechts <i>28 Dagen</i>
              </Title> 
              <Title className={classes.second_title} order={1} mb="md" style={{ display: 'flex', alignItems: 'center', gap: '45px' }}>
                Geen voorkennis vereist
              </Title> 
              <Text className="subtext" fz={{ base: 'md', md: 'xl' }} fw={400} mb={{ base: 'lg', md: '0' }} c={{ base: '#fff', md: '#eee' }} >
              Ontdek hoe mijn bewezen aanpak je helpt om eindelijk vooruitgang te boeken, met volledige begeleiding en een persoonlijk plan.
              </Text>
              <a href="#offer" className="button button-red d-flex mt-3 mt-md-5 justify-content-center">
                Ik wil mijn lichaam transformeren
              </a>
            </Flex>

          </Grid.Col>
          <Grid.Col sm={12} md={6} offset={.5} p="0" mr="lg">
            <Box className={ classes.swiper }>
              <AnimatedSlider testimonials={allTestimonials ? allTestimonials : null} />
            </Box> 
          </Grid.Col>
        </Flex>
      </Grid>
    </Container>
  );
}
 
export default HeaderReview;