const USPs = () => {
  return (
    <div className="container">

      <div className="row">
        <div className="col-md-12">
          <h2 className="mt-2 mb-5 text-black text-[32px] font-bold text-center">
          Wat je kunt bereiken met de juiste aanpak:
          </h2>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12"> 
          <div className="usps text-center">
            {/* <div className="usp-item">
              <h5>Unieke begeleiding</h5>
              <p>
                1-op-1 <i>ONLINE</i> begeleiding van een expert op het gebied van spieropbouw bij mannen
              </p>
            </div> */}
            <div className="usp-item text-center" style={{ paddingTop: '40px' }}>
              <h5 className="font-bold text-center mx-auto">Meer spiermassa en kracht.</h5>
              {/* <p className="text-center mx-auto">
              Persoonlijk afgestemde trainingen voor jouw unieke doelen.
              </p> */}
            </div>
            {/* <div className="usp-item">
              <h5>Geen strikt voedingsschema</h5>
              <p>
              Geen gedoe met calorieën tellen of strikte diëten. Je leert hoe je met minimale aanpassingen grote resultaten kunt behalen.              </p>
            </div> */}
            <div className="usp-item text-center" style={{ paddingTop: '40px' }}>
              <h5 className="font-bold text-center mx-auto">Een flexibele trainingsroutine die bij jou past.</h5>
              {/* <p className="text-center mx-auto">
              Een flexibele aanpak die past in elke agenda.
              </p> */}
            </div>
            <div className="usp-item text-center" style={{ paddingTop: '40px' }}>
              <h5 className="font-bold text-center mx-auto">Resultaten die je zelfvertrouwen vergroten.</h5>
              {/* <p className="text-center mx-auto">
              Praktische tips om consistent vooruitgang te boeken.
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
 
export default USPs;