import React, { useState } from 'react';
import { ChevronRight, ChevronLeft, Loader2, X } from 'lucide-react';
import ReactPixel from 'react-facebook-pixel';

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

ReactPixel.init('336340599280167', options);

const quizData = {
  questions: [
    {
      id: 1,
      question: "Wat is jouw belangrijkste fitnessdoel?",
      options: [
        { id: 'a', text: "Meer spiermassa", result: "muscle_mass" },
        { id: 'b', text: "Meer kracht", result: "strength" },
        { id: 'c', text: "Afvallen en fitter worden", result: "fat_loss" },
        { id: 'd', text: "Ik wil gewoon een duidelijk plan", result: "clear_plan" }
      ]
    },
    {
      id: 2,
      question: "Heb je momenteel een gestructureerd trainingsplan?",
      options: [
        { id: 'a', text: "Ja, maar ik twijfel eraan of het werkt", result: "doubting_plan" },
        { id: 'b', text: "Nee, ik volg geen specifiek plan", result: "no_plan" },
        { id: 'c', text: "Soms, maar ik ben niet consistent", result: "inconsistent_plan" }
      ]
    },
    {
      id: 3,
      question: "Houd je je progressie bij tijdens het trainen?",
      options: [
        { id: 'a', text: "Ja, ik noteer alles", result: "tracking_all" },
        { id: 'b', text: "Af en toe, maar niet consequent", result: "tracking_sometimes" },
        { id: 'c', text: "Nee, ik heb geen idee hoe ik dit moet doen", result: "no_tracking" }
      ]
    },
    {
      id: 4,
      question: "Hoe bepaal je hoeveel gewicht, sets en herhalingen je moet doen?",
      options: [
        { id: 'a', text: "Ik gok of volg algemene schema's", result: "guessing" },
        { id: 'b', text: "Mijn coach of trainer helpt mij hiermee", result: "coached" },
        { id: 'c', text: "Ik gebruik een systeem, maar weet niet of het klopt", result: "unsure_system" }
      ]
    },
    {
      id: 5,
      question: "Krijg je momenteel begeleiding bij je techniek en uitvoering?",
      options: [
        { id: 'a', text: "Ja, maar alleen af en toe", result: "occasional_guidance" },
        { id: 'b', text: "Nee, ik doe het allemaal zelf", result: "no_guidance" },
        { id: 'c', text: "Ik zou graag meer feedback willen", result: "wants_feedback" }
      ]
    },
    {
      id: 6,
      question: "Hoe gemotiveerd ben je om consistent te trainen?",
      options: [
        { id: 'a', text: "Ik ben supergemotiveerd", result: "highly_motivated" },
        { id: 'b', text: "Ik heb een stok achter de deur nodig", result: "needs_push" },
        { id: 'c', text: "Ik verlies vaak motivatie na een paar weken", result: "loses_motivation" }
      ]
    }
  ]
};

const generateLocalSummary = (answers) => {
  console.log('Generating local summary for:', answers);
  
  // Check if we have all required answers
  if (!Array.isArray(answers) || answers.length < 6) {
    return 'We hebben niet genoeg informatie om een persoonlijk advies te genereren. Probeer de vragenlijst opnieuw.';
  }

  try {
    const [goal, plan, tracking, programming, technique, motivation] = answers;
    let summary = '';

    // Goal-based intro
    if (goal.result === 'muscle_mass') {
      summary += 'Je doel om meer spiermassa op te bouwen vereist een gestructureerd programma met progressieve overload. ';
    } else if (goal.result === 'strength') {
      summary += 'Voor het ontwikkelen van maximale kracht is een doordacht trainingsplan essentieel. ';
    } else if (goal.result === 'fat_loss') {
      summary += 'Je wens om af te vallen en fitter te worden vraagt om een gecombineerde aanpak van training en progressie. ';
    } else if (goal.result === 'clear_plan') {
      summary += 'Je behoefte aan een duidelijk plan laat zien dat je serieus bent over je training. ';
    }

    // Challenge-based advice
    if (plan.result.includes('no_plan') || plan.result.includes('inconsistent')) {
      summary += 'Met mijn gestructureerde aanpak en wekelijkse schema\'s krijg je de structuur die je nodig hebt. ';
    } else if (technique.result.includes('no_guidance') || technique.result.includes('wants_feedback')) {
      summary += 'Door mijn persoonlijke begeleiding en focus op techniek zul je met meer vertrouwen kunnen trainen. ';
    } else if (motivation.result.includes('needs_push') || motivation.result.includes('loses_motivation')) {
      summary += 'De persoonlijke coaching en progressie tracking zullen je helpen gemotiveerd te blijven. ';
    }

    // Add specific value based on their tracking/programming needs
    if (tracking.result !== 'tracking_all') {
      summary += 'In de app kan je eenvoudig je progressie bijhouden en zien of je op schema ligt. ';
    }
    if (programming.result === 'guessing' || programming.result === 'unsure_system') {
      summary += 'Je krijgt een exact trainingsschema met de juiste gewichten, sets en herhalingen voor jouw niveau. ';
    }

    return summary.trim();
  } catch (error) {
    console.error('Error generating local summary:', error);
    return 'We hebben niet genoeg informatie om een persoonlijk advies te genereren. Probeer de vragenlijst opnieuw.';
  }
};

const determineChallenge = (answers) => {
  // Logic to determine the user's biggest challenge based on their answers
  const lastThreeAnswers = answers.slice(-3);
  
  if (lastThreeAnswers.some(a => a.result.includes('no_plan') || a.result.includes('inconsistent'))) {
    return 'een gebrek aan structuur';
  }
  if (lastThreeAnswers.some(a => a.result.includes('no_guidance') || a.result.includes('wants_feedback'))) {
    return 'onzekerheid over techniek';
  }
  if (lastThreeAnswers.some(a => a.result.includes('loses_motivation') || a.result.includes('needs_push'))) {
    return 'motivatie';
  }
  return 'consistentie in je training';
};

const SignupForm = ({ onComplete, answers, aiSummary }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    try {
      // Format the quiz data
      const quizResults = answers.map((answer, index) => {
        return {
          question: quizData.questions[index].question,
          answer: answer.text,
          result: answer.result // if you want to include the result code
        }
      });
  
      // Create a single JSON object with all data
      const makeData = {
        userData: {
          name: formData.name,
          email: formData.email,
          phone: formData.phone || 'Not provided'
        },
        quizResults: JSON.stringify(quizResults), // Convert array to JSON string
        aiSummary: aiSummary,
        timestamp: new Date().toISOString()
      };

      ReactPixel.track('Lead', {
        value: 14.50,
        currency: "EUR",
        status: 'free_trial_signup'
      });

      // Replace this URL with your Make.com webhook URL
      const MAKE_WEBHOOK_URL = 'https://hook.eu2.make.com/mqgefrai0pw4h0at1hop5gec19m5p1ev';
  
      // Make.com webhook URL
      const response = await fetch(MAKE_WEBHOOK_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(makeData)
      });

      if (!response.ok) {
        throw new Error('Failed to submit form');
      }

      setIsSubmitted(true);
      console.log('Form submitted successfully:', makeData);
    } catch (err) {
      console.error('Error submitting form:', err);
      setError('Er is iets misgegaan. Probeer het later opnieuw.');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isSubmitted) {
    return (
      <div className="text-center py-8">
        <div className="mb-6">
          <svg style={{ maxWidth: '60px' }} className="w-16 h-16 text-green-500 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
          </svg>
        </div>
        <h3 className="text-2xl font-bold mb-4">Bedankt!</h3>
        <p className="text-gray-600">
          We hebben je gegevens ontvangen. Bekijk je e-mail voor je persoonlijke plan en toegang tot de app.
        </p>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="space-y-6 flex text-left flex-col gap-4">
      {error && (
        <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-lg">
          {error}
        </div>
      )}
      <div className="text-left">
        <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
          Naam
        </label>
        <input
          type="text"
          id="name"
          required
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-red-500 focus:border-red-500"
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          disabled={isSubmitting}
        />
      </div>
      <div>
        <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
          E-mailadres
        </label>
        <input
          type="email"
          id="email"
          required
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-red-500 focus:border-red-500"
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          disabled={isSubmitting}
        />
      </div>
      <button
        type="submit"
        disabled={isSubmitting}
        className="w-full bg-red-600 text-white font-semibold px-8 py-4 rounded-lg text-lg hover:bg-red-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
      >
        {isSubmitting ? (
          <div className="flex items-center justify-center">
            <Loader2 className="w-5 h-5 animate-spin mr-2" />
            Wordt verzonden...
          </div>
        ) : (
          'Ja, stuur mij mijn gratis plan!'
        )}
      </button>
    </form>
  );
};

const QuizResults = ({ answers, aiSummary, isLoading, onComplete }) => {
  const [showSignup, setShowSignup] = useState(false);
  const challenge = determineChallenge(answers);

  const handleShowSignup = async () => {
    try {
      // Format the quiz data
      const quizResults = answers.map((answer, index) => ({
        question: quizData.questions[index].question,
        answer: answer.text,
        result: answer.result
      }));

      await fetch('https://hook.eu2.make.com/i4xclwm7v7lhfait3shgm2ds8orbge8v', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          event: 'quiz_completed',
          timestamp: new Date().toISOString()
        })
      });
    } catch (error) {
      console.error('Failed to log quiz completion:', error);
    }
    setShowSignup(true);
  };

  if (showSignup) {
    return <SignupForm 
      onComplete={onComplete}
      answers={answers}
      aiSummary={aiSummary}
    />;
  }

  return (
    <div className="space-y-8">
      <div className="text-left">
        <h2 className="text-3xl text-black font-bold mb-4">Dit is hoe jij jouw doelen bereikt</h2>
        {isLoading ? (
          <div className="flex flex-col items-center justify-center py-12">
            <Loader2 className="w-8 h-8 animate-spin text-red-600 mb-4" />
            <p className="text-gray-600">Je persoonlijke advies wordt gegenereerd...</p>
          </div>
        ) : (
          <div className="prose prose-lg max-w-none">
            <p className="text-gray-700">
              {aiSummary}
              <br/><br/>
              Omdat ik geloof in mijn methode en wil dat jij dit zelf kunt ervaren, bied ik nu een gratis proefpakket aan. Zo kun je zonder risico ontdekken hoe effectief het is. Dit is wat je krijgt:
            </p>
            <div className="mt-6">
              <ul className="space-y-4 mt-4">
                <li className="flex items-start gap-3 text-gray-700">
                  <svg className="w-6 h-6 text-green-500 flex-shrink-0 mt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                  <span>Een persoonlijk trainingsplan, volledig afgestemd op jouw doelen.</span>
                </li>
                <li className="flex items-start gap-3 text-gray-700">
                  <svg className="w-6 h-6 text-green-500 flex-shrink-0 mt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                  <span>2 weken gratis toegang tot mijn app, waarin ik wekelijks jouw workouts voor je klaarzet</span>
                </li>
                <li className="flex items-start gap-3 text-gray-700">
                  <svg className="w-6 h-6 text-green-500 flex-shrink-0 mt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                  <span>2 weken gratis begeleiding om je voortgang te garanderen.</span>
                </li>
              </ul>
              <p className="text-gray-700 mt-4">
              Je betaald nog <b>niks</b> en er wordt na 2 weken ook niet ineens automatisch iets afgeschreven, dit is <b>echt 100% gratis</b>.
              <br/><br/>
                Na de proefperiode zou ik graag willen dat je doorgaat voor een klein maandbedrag, maar je kunt ook zelfstandig gratis verder gaan met je schema. Ik bied dit gratis aan, omdat ik ervan overtuigd ben dat je de waarde zult inzien. Maar die keuze is helemaal aan jou. Probeer het nu zonder verplichtingen!
              </p>
            </div>
          </div>
        )}
      </div>

      <div className="flex flex-col md:flex-row gap-4 mt-4">
        <button
          onClick={handleShowSignup}
          className="flex-1 bg-red-600 text-white font-semibold px-8 py-4 rounded-lg text-lg hover:bg-red-700 transition-colors"
        >
          Ja, stuur mij mijn gratis plan!
        </button>
        <button
          onClick={onComplete}
          className="bg-gray-200 text-gray-700 font-semibold px-8 py-4 rounded-lg text-lg hover:bg-gray-300 transition-colors"
        >
          Sluiten
        </button>
      </div>
    </div>
  );
};

const QuizModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleQuizOpen = async () => {
    try {
      await fetch('https://hook.eu2.make.com/gtmbqn4i2y6lq6dyut1x3bcl4tkyrwn6', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          event: 'quiz_opened',
          timestamp: new Date().toISOString()
        })
      });
    } catch (error) {
      console.error('Failed to log quiz open:', error);
    }
    setIsOpen(true);
  };

  return (
    <div id="offer" className="waarom-section" style={{ background: 'var(--red)'}}>
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
      {/* Button to open the quiz */}
      <h2 className="text-3xl text-white font-bold mb-3">Ontdek jouw ideale aanpak voor maximale spiergroei</h2>
      <p className="text-white">
      Beantwoord 6 korte vragen en ontdek wat jou tegenhoudt én hoe je jouw doelen kunt bereiken. Simpel, snel en persoonlijk.
      </p>
      <button
        onClick={handleQuizOpen}
        className="inline-block bg-white mt-5 text-black font-semibold px-8 py-4 rounded-lg text-lg hover:bg-red-700 transition-colors"
      >
        Start mijn Quiz
      </button>

      {/* Modal Overlay */}
      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
          <div className="bg-gray-100 rounded-xl w-full max-w-4xl max-h-[90vh] overflow-y-auto relative">
            {/* Close button */}
            <button
              onClick={() => setIsOpen(false)}
              className="absolute top-4 right-4 p-2 hover:bg-gray-200 rounded-full transition-colors"
            >
              <X className="w-6 h-6 text-gray-500" />
            </button>

            {/* Quiz Content */}
            <FitnessQuiz onComplete={() => setIsOpen(false)} />
          </div>
        </div>
      )}
    </div>
    </div>
    </div>
    </div>
  );
};

const FitnessQuiz = ({ onComplete }) => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [aiSummary, setAiSummary] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const generateAISummary = async (userAnswers) => {
    setIsLoading(true);
    try {
      console.log('User answers:', userAnswers);
      console.log('Number of questions:', quizData.questions.length);

      // Only take the number of answers that match our questions
      const limitedAnswers = userAnswers.slice(0, quizData.questions.length);
      
      const formattedAnswers = limitedAnswers.map((answer, index) => ({
        question: quizData.questions[index].question,
        answer: answer.text,
        category: answer.result
      }));

      console.log('Formatted answers:', formattedAnswers);

      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`
        },
        body: JSON.stringify({
          model: "gpt-4",
          messages: [
            {
              "role": "system",
              "content": `Je bent een ervaren personal trainer die mensen motiveert en adviseert. Je methode bestaat uit:
- Een trainingsplan op maat dat bij hun doelen past
- Progressie bijhouden voor optimale resultaten
- Wekelijkse workouts met exacte sets, reps, rusttijd
- Begeleiding bij techniek en uitvoering
- Persoonlijke coaching voor motivatie en consistentie`
            },
            {
              "role": "user",
              "content": `Analyseer deze persoon's antwoorden en genereer een kort, persoonlijk advies.
Focus op:
1. Een samenvatting van hun situatie en grootste uitdaging
2. Waarom jouw methode specifiek voor hen zou werken
3. Hoe jouw specifieke aanpak hun probleem oplost

Zorg dat je 1-2 specifieke elementen van jouw methode benoemt die hun grootste uitdaging oplost.
Hou het persoonlijk, motiverend en kort (max 4 zinnen).

De antwoorden van deze persoon:
${JSON.stringify(formattedAnswers, null, 2)}

Denk aan de onderdelen van jouw methode:
- Trainingsplan op maat voor hun specifieke doelen
- Wekelijkse workouts met vooraf bepaalde sets/reps/rust
- Progressie tracking voor optimale resultaten
- Techniek begeleiding en advies
- Persoonlijke coaching voor motivatie`
            }
          ],
          temperature: 0.7,
          max_tokens: 500
        })
      });

      const data = await response.json();
      setAiSummary(data.choices[0].message.content);
      setShowResults(true);
    } catch (error) {
      console.error('Failed to generate AI summary:', error);
      // Fallback to local generation
      const summary = generateLocalSummary(userAnswers);
      setAiSummary(summary);
      setShowResults(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAnswer = async (answer) => {
    // Only add answer if we haven't reached the maximum number of questions
    if (answers.length < quizData.questions.length) {
      const newAnswers = [...answers, answer];
      setAnswers(newAnswers);
      
      if (currentQuestion + 1 < quizData.questions.length) {
        setCurrentQuestion(currentQuestion + 1);
      } else {
        setShowResults(false); // Make sure results aren't shown during loading
        await generateAISummary(newAnswers);
      }
    }
  };

  const handlePrevious = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
      setAnswers(answers.slice(0, -1));
    }
  };

  // Show loading screen
  if (isLoading) {
    return (
      <div className="w-full max-w-4xl mx-auto px-4 py-8">
        <div className="bg-white rounded-xl p-8 text-center">
          <div className="flex flex-col items-center justify-center py-12">
            <Loader2 className="w-12 h-12 animate-spin text-red-600 mb-4" />
            <p className="text-xl text-gray-600">
              Je persoonlijke advies wordt gegenereerd...
            </p>
            <p className="text-sm text-gray-500 mt-2">
              Dit kan enkele seconden duren
            </p>
          </div>
        </div>
      </div>
    );
  }

  // Show results
  if (showResults) {
    return (
      <div className="w-full max-w-4xl mx-auto px-4 py-8">
        <div className="rounded-xl">
          <QuizResults
            answers={answers}
            aiSummary={aiSummary}
            isLoading={isLoading}
            onComplete={onComplete}
          />
        </div>
      </div>
    );
  }

  // Show questions
  return (
    <div className="w-full max-w-4xl mx-auto px-4 py-8">
      <div className="">
        <div className="mb-8">
          <div className="flex justify-between items-center mb-6">
            <span className="text-sm font-semibold text-gray-500">
              Vraag {currentQuestion + 1} van {quizData.questions.length}
            </span>
            {currentQuestion > 0 && (
              <button 
                onClick={handlePrevious}
                className="flex items-center text-gray-500 hover:text-gray-700"
              >
                <ChevronLeft className="w-5 h-5 mr-1" />
                Vorige
              </button>
            )}
          </div>
          <h2 className="text-2xl text-black font-bold mb-4">
            {quizData.questions[currentQuestion].question}
          </h2>
        </div>

        <div className="space-y-4">
          {quizData.questions[currentQuestion].options.map((option) => (
            <button
              key={option.id}
              onClick={() => handleAnswer(option)}
              className="w-full text-left p-4 rounded-lg border-2 border-gray-200 hover:border-red-500 transition-colors flex items-center justify-between group"
            >
              <span className="text-gray-700 group-hover:text-red-600">
                {option.text}
              </span>
              <ChevronRight className="w-5 h-5 text-gray-400 group-hover:text-red-500" />
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default QuizModal;