import AppContext from '../../AppContext';

import Countdown, { zeroPad } from 'react-countdown';

import { useContext, useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";

import axios from 'axios';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

function Stappenplan() {
  const { globalState, updateGlobalState } = useContext(AppContext);
 
  const [activeScience, setActiveScience] = useState(1);

  return (
    <div className="waarom-section voorwie-section">
      <div className="container">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <div className="section-heading dark-heading text-center mx-auto">
              {/* <h6>Werkwijze</h6>  */}
              <h2 className="mt-2 mb-4">De oplossing</h2>
              <p>
              Mijn trainingsplan groeit met je mee
              </p>
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-8 science-container mx-auto">
            <div className={`science-item ${activeScience == 1 ? 'active' : null}`}>
              <h5 onClick={() => setActiveScience(1)}>
                1. Vul een intake-formulier in
                <svg width="800" height="468" viewBox="0 0 800 468" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M789.6 9.99853C776.5 -3.00146 755.3 -3.00146 742.267 9.99853L399.467 386.329L56.7344 9.99853C43.6677 -3.00146 22.4663 -3.00146 9.39961 9.99853C-3.66706 23.3319 -3.66706 44.6629 9.39961 57.6629L374.166 457.997C381.133 464.997 390.367 467.998 399.467 467.331C408.6 467.998 417.833 464.997 424.833 457.997L789.6 57.6629C802.633 44.6629 802.633 23.3319 789.6 9.99853Z" fill="black"/>
                </svg>
              </h5>
              <div className="science-body">
                <p>
                Deel je doelen, ervaring en beschikbaarheid. Ik maak een persoonlijk plan dat perfect past bij jouw situatie.
                </p>
              </div>
            </div>
            <div className={`science-item ${activeScience == 2 ? 'active' : null}`}>
              <h5 onClick={() => setActiveScience(2)}>
                2. Ontvang jouw persoonlijke trainingsplan
                <svg width="800" height="468" viewBox="0 0 800 468" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M789.6 9.99853C776.5 -3.00146 755.3 -3.00146 742.267 9.99853L399.467 386.329L56.7344 9.99853C43.6677 -3.00146 22.4663 -3.00146 9.39961 9.99853C-3.66706 23.3319 -3.66706 44.6629 9.39961 57.6629L374.166 457.997C381.133 464.997 390.367 467.998 399.467 467.331C408.6 467.998 417.833 464.997 424.833 457.997L789.6 57.6629C802.633 44.6629 802.633 23.3319 789.6 9.99853Z" fill="black"/>
                </svg>
              </h5>
              <div className="science-body">
                <p>
                Je trainingsplan staat klaar in mijn app. Alles wordt voor je gepland – sets, reps, rusttijden en oefeningen – afgestemd op jouw niveau en doelen. Je hoeft het alleen te volgen.
                </p>
              </div>
            </div>
            <div className={`science-item ${activeScience == 3 ? 'active' : null}`}>
              <h5 onClick={() => setActiveScience(3)}>
                3. Houd eenvoudig je trainingen bij
                <svg width="800" height="468" viewBox="0 0 800 468" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M789.6 9.99853C776.5 -3.00146 755.3 -3.00146 742.267 9.99853L399.467 386.329L56.7344 9.99853C43.6677 -3.00146 22.4663 -3.00146 9.39961 9.99853C-3.66706 23.3319 -3.66706 44.6629 9.39961 57.6629L374.166 457.997C381.133 464.997 390.367 467.998 399.467 467.331C408.6 467.998 417.833 464.997 424.833 457.997L789.6 57.6629C802.633 44.6629 802.633 23.3319 789.6 9.99853Z" fill="black"/>
                </svg>
              </h5>
              <div className="science-body">
                <p>
                Je kunt eenvoudig tijdens het trainen je workouts bijhouden met gebruik van onze app. Zie precies welke oefeningen je moet doen, gebruik een timer voor je rustperiodes en bekijk voorbeeldvideo's voor uitvoering van oefeningen.
                </p>
              </div>
            </div>
            <div className={`science-item ${activeScience == 3 ? 'active' : null}`}>
              <h5 onClick={() => setActiveScience(3)}>
                4. Elke week worden je workouts voor je klaargezet
                <svg width="800" height="468" viewBox="0 0 800 468" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M789.6 9.99853C776.5 -3.00146 755.3 -3.00146 742.267 9.99853L399.467 386.329L56.7344 9.99853C43.6677 -3.00146 22.4663 -3.00146 9.39961 9.99853C-3.66706 23.3319 -3.66706 44.6629 9.39961 57.6629L374.166 457.997C381.133 464.997 390.367 467.998 399.467 467.331C408.6 467.998 417.833 464.997 424.833 457.997L789.6 57.6629C802.633 44.6629 802.633 23.3319 789.6 9.99853Z" fill="black"/>
                </svg>
              </h5>
              <div className="science-body">
                <p>
                Ik, als jouw coach, pas je plan wekelijks aan op basis van jouw resultaten die je bijhoudt in de app. Het gewicht dat je wilt gebruiken, het aantal herhalingen, de rusttijd - alles wordt voor je uitgedacht.
                </p>
              </div>
            </div>
            <div className={`science-item ${activeScience == 4 ? 'active' : null}`}>
              <h5 onClick={() => setActiveScience(4)}>
                5. Zelfstandig of samen verder
                <svg width="800" height="468" viewBox="0 0 800 468" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M789.6 9.99853C776.5 -3.00146 755.3 -3.00146 742.267 9.99853L399.467 386.329L56.7344 9.99853C43.6677 -3.00146 22.4663 -3.00146 9.39961 9.99853C-3.66706 23.3319 -3.66706 44.6629 9.39961 57.6629L374.166 457.997C381.133 464.997 390.367 467.998 399.467 467.331C408.6 467.998 417.833 464.997 424.833 457.997L789.6 57.6629C802.633 44.6629 802.633 23.3319 789.6 9.99853Z" fill="black"/>
                </svg>
              </h5>
              <div className="science-body">
                <p>
                Na 4 weken heb je een plan en kennis die je zelfstandig kunt blijven toepassen – of je kiest ervoor om door te gaan en nóg meer progressie te boeken.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-7 mx-auto my-4">
            <p>
              <i>  
                Of je nu kiest voor zelfstandig trainen of doorgaat met onze begeleiding, je hebt alles wat je nodig hebt om blijvend resultaat te behalen.  
              </i>
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 d-flex justify-content-center">
            
            <a href="#offer" className="button button-red d-flex mt-4 justify-content-center">
              Ik wil mijn lichaam transformeren
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Stappenplan;