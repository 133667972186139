import AppContext from '../../AppContext';

import Countdown, { zeroPad } from 'react-countdown';

import './Page.css';

import { Crisp } from "crisp-sdk-web";

import { useContext, useState } from 'react';
import { useEffect } from 'react';

import { useNavigate, useSearchParams } from "react-router-dom";

import axios from 'axios';
import USPs from './USPs';
import Schema from './Schema';
import Wetenschap from './Wetenschap';
import ReviewsList from './ReviewsList';
import OfferPakket from './OfferPakket';
import OfferCard from './OfferPakketCard';
import Gifts from './Gifts';
import Insta from './Instagram';
import Header from './Header';
import Intro from './Intro';
import Resultaten from './Resultaten';
import StickyAtc from './StickyAtc';
import FeaturedReview from './FeaturedReview';
import JouwCoach from './JouwCoach';
import Traject from './Traject';
import Verwachten from './Verwachten';
import VoorWie from './VoorWie';
import VoorWieNieuw from './VoorWieNieuw';
import NietVoorJou from './NietVoorJou';
import WatMoetIkLaten from './WatMoetIkLaten';
import Garantie from './Garantie';
import Twijfels from './Twijfels';
import WatNodig from './WatNodig';
import DeChallenge from './DeChallenge';
import Waarom from './Waarom';
import TextImage from './TextImage';
import HeaderReview from './HeaderReview';
import OfferItems from './OfferItems';
import Facebook from './Facebook'; 
import WatJeKrijgt from './WatJeKrijgt';
import Cta from '../Namaak/Cta';
import Stappenplan from './Stappenplan';
import DeApp from './DeApp';
import FitnessQuiz from './QuizApp';

function GoedeVoornemens({ defaultPrice }) {

  Crisp.configure('3a766b7e-34c5-4cea-8d7c-8b42d4bb1ed6', {
    autoload: false
  });

  useEffect(() => {
    Crisp.load();
  }, [])
  
  const { globalState, updateGlobalState } = useContext(AppContext);

  var today = new Date();
  today.setHours(today.getHours() + 4);

  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return false;
    } else {
      // Render a countdown
      return (
        <div className="timer" style={{ display: 'flex' }}>
          {/* <div className="timer-desc">
            <span style={{ whiteSpace: "nowrap" }}>ik zoek nog</span>
          </div> */}
          {/* <div className="timer-box">
            <span>2</span>
          </div> */}
          {/* <div className="timer-desc">
            <span>klanten</span>
          </div> */}
          <div className="timer-box">
            <span>{ zeroPad(days) }</span>
            <span>dagen</span>
          </div>
          <div className="timer-box">
            <span>{ zeroPad(hours) }</span>
            <span>uren</span>
          </div>
          <div className="timer-box">
            <span>{ zeroPad(minutes) }</span>
            <span>minuten</span>
          </div>
          <div className="timer-box">
            <span>{ zeroPad(seconds) }</span>
            <span>seconden</span>
          </div>
        </div>
      );
    }
  };
  
  const [searchParams, setSearchParams] = useSearchParams();

	const navigate = useNavigate();

  const [activeFaq, setActiveFaq] = useState(0);
  const faqs = [
    {
      "vraag": "Is dit programma geschikt voor beginners?",
      "antwoord": "Ja! Of je nu net begint of al jaren traint, jouw trainingsplan wordt volledig afgestemd op jouw niveau en doelen. Beginners leren de juiste technieken voor hun persoonlijke situatie, terwijl gevorderden hun progressie kunnen versnellen."
    },
    {
      "vraag": "Hoeveel tijd kost dit per week?",
      "antwoord": "Dat bepaal je zelf! Jij geeft aan hoeveel dagen je beschikbaar bent en hoe lang je wilt trainen (bijvoorbeeld 30, 45 of 60 minuten per sessie). Ik pas je schema daarop aan, zodat het perfect in jouw agenda past."
    },
    {
      "vraag": "Wat gebeurt er na de eerste 4 weken?",
      "antwoord": "Na de eerste 4 weken kun je ervoor kiezen om door te gaan met mijn begeleiding voor slechts €29 per maand. Je schema wordt wekelijks aangepast op jouw voortgang. Als je niet verder wilt, heb je nog steeds een plan en kennis die je zelfstandig kunt blijven gebruiken."
    },
    {
      "vraag": "Wat als ik vragen heb of een oefening niet begrijp?",
      "antwoord": "Je kunt altijd contact opnemen met mij, jouw coach, via de app. Bovendien zijn alle oefeningen voorzien van duidelijke voorbeeldvideo's, zodat je altijd weet wat je moet doen."
    },
    {
      "vraag": "Hoe weet ik dat dit programma werkt voor mij?",
      "antwoord": "Jouw trainingsplan wordt volledig gepersonaliseerd. Jouw intakeformulier bepaalt hoe jouw schema eruitziet, zodat het specifiek is afgestemd op jouw doelen, niveau en beschikbaarheid. Ik gebruik bewezen methodes die werken voor iedereen – of je nu wilt aankomen in spiermassa, sterker wilt worden, of gewoon een plan nodig hebt dat écht werkt."
    },
    {
      "vraag": "Moet ik toegang hebben tot een sportschool?",
      "antwoord": "Hoewel het programma ideaal is voor gebruik in de sportschool, kan ik ook een schema maken dat je thuis kunt volgen. Geef in je intake aan wat voor apparatuur je tot je beschikking hebt, en ik ontwerp een plan dat daarbij past."
    },
    {
      "vraag": "Hoe snel zie ik resultaten?",
      "antwoord": "De meeste klanten merken na de eerste 2-3 weken al verbetering in kracht, uithoudingsvermogen en spierdefinitie. Na 4 weken zie je duidelijk verschil – zowel fysiek als mentaal. Het exacte tempo hangt af van je startniveau, doelen en consistentie."
    },
    {
      "vraag": "Wat maakt dit anders dan andere apps of schema's?",
      "antwoord": "In tegenstelling tot standaard apps of statische schema's, is mijn programma volledig gepersonaliseerd én dynamisch. Dat betekent dat jouw schema wekelijks wordt aangepast op jouw voortgang. Bovendien heb je altijd een coach om vragen aan te stellen en je te begeleiden."
    },
    {
      "vraag": "Wat als ik mijn schema niet haal?",
      "antwoord": "Geen probleem! Ik begrijp dat het leven soms druk kan zijn. Geef aan wat er speelt, en ik pas je schema aan zodat het haalbaar blijft. Consistentie is belangrijker dan perfectie."
    },
    {
      "vraag": "Is er een garantie dat ik resultaat zie?",
      "antwoord": "Hoewel resultaat afhankelijk is van jouw inzet en consistentie, garandeer ik dat je na 4 weken de kennis en tools hebt om vooruitgang te boeken. Mijn klanten zien vrijwel altijd verbetering in kracht, spiermassa en zelfvertrouwen. Wanneer je écht niet blij bent met je behaalde resultaten, dan bied ik een 100% geld-terug-garantie."
    }
  ];


  

  const [activeObjection, setActiveObjection] = useState(0); 
  const objectionHandlers = [
    {
      "vraag": "\"Ik heb al eerder geprobeerd om spiermassa op te bouwen, maar het lukte me niet. Waarom zou het deze keer anders zijn?\"",
      "antwoord": "Dat begrijp ik. Deze challenge biedt echter persoonlijke begeleiding en op maat gemaakte trainingsschema's die specifiek zijn ontworpen om jou te helpen jouw doelen te bereiken. Met de juiste begeleiding en ondersteuning is succes binnen handbereik."
    },
    {
      "vraag": "\"Ik ben niet zeker of ik genoeg tijd heb om me volledig aan de challenge te wijden. Hoe kan ik er zeker van zijn dat ik mijn schema kan volgen?\"",
      "antwoord": "We begrijpen dat het leven druk kan zijn, maar de trainingsschema's zijn flexibel en kunnen worden aangepast aan jouw schema. Onze coaches zullen je helpen om realistische doelen te stellen en je ondersteunen bij het vinden van manieren om de challenge in je drukke leven te passen."
    },
    {
      "vraag": "\"Ik heb geen ervaring met krachttraining. Is deze challenge geschikt voor beginners?\"",
      "antwoord": "Absoluut! Deze challenge is ontworpen voor mensen van alle fitnessniveaus, inclusief beginners. Onze coaches zullen je begeleiden bij elke stap van je fitnessreis en ervoor zorgen dat je de juiste technieken leert en veilig vooruitgang boekt."
    },
    {
      "vraag": "\"Ik ben bang dat ik mijn voortgang niet zal kunnen bijhouden of dat ik niet gemotiveerd genoeg zal zijn om door te zetten. Hoe kan ik hiermee omgaan?\"",
      "antwoord": "Het is normaal om af en toe twijfels te hebben, maar we zijn hier om je te ondersteunen. Onze challenge omvat regelmatige voortgangsbeoordelingen en persoonlijke begeleiding om je gemotiveerd te houden en eventuele obstakels te overwinnen. Samen zullen we jouw succes verzekeren."
    }
  ]
  
  
  
  
  
  
  return (
    <>
      {/* <StickyAtc defaultPrice={defaultPrice} /> */}

      <HeaderReview />

{/* <Intro /> */}

{/* <div
  className="container pt-5 mx-auto px-4 py-16 grid grid-cols-12 gap-[20px] md:gap-[50px] items-center"
  style={{ paddingTop: '27px', paddingBottom: '27px' }}
>
  <div className="col-span-12 md:col-span-6 mt-4 md:mt-0 md:order-1 mr-auto">
    <img
      src="/img/stef-schrijven.jpg" 
      alt="Background"
      className="object-cover h-full w-full max-w-[100%] max-h-[430px] rounded-[16px]"
    />
  </div>

  <div className="col-span-12 md:col-span-6 md:order-2">
    <div className="text-black text-md">
      Dag in, dag uit naar de sportschool gaan, maar geen ‘echte resultaten’ zien. Geen idee hebben waar je moet beginnen, welk gewicht je moet gebruiken of hoeveel herhalingen je precies moet doen.
      <br/><br/>
      Ik heb honderden mensen geholpen om dit patroon te doorbreken. Laat mij je helpen en samen gaan we voor de beste resultaten die je kunt halen.
      <br/><br/>
      <b>Ik ben Stef van Healthy Living Project en ik help jou naar de beste versie van jezelf.</b>
    </div>
    <div className="flex space-x-8 items-center">
      <a
        href="#offer"
        className="bg-primary-red text-white px-10 py-6 rounded-[10px] font-bold flex space-x-2 items-center mt-5"
      >
        <span>Ik ben klaar voor verandering</span>
      </a>
    </div>
  </div>
</div> */}

{/* <VoorWie /> */}

<VoorWieNieuw />

<USPs /> 

<FitnessQuiz />

{/* <Verwachten /> */}

      {/* <Header / > */}

      {/* <Schema /> */}

{/* <Facebook /> */}

{/* <Waarom /> */}

{/* <Resultaten /> */}

{/* <DeChallenge /> */}

{/* <OfferItems /> */}

{/* <Stappenplan /> */}

{/* <TextImage
  image={'/img/fullbody-schema.png'}
  imageBlur={false}
  subtitle="Training"
  title="Trainingsschema"
  content={`
    Mijn allerbeste trainingsprogramma met de beste resultaten.
    <br/><br/>
    Terug naar de basis. Oefeningen die veel spieren tegelijk aanpakken.
    <br/><br/>
    Geen nutteloze oefeningen die er alleen leuk uitzien voor op Instagram.
    <br/><br/>
    Je kunt kiezen voor verschillende varianten: voor beginners, gevorderden en experts.
    <br/><br/>
    Ook het aantal trainingsdagen per week is aan te passen. Kun je een keer 2 in plaats van 3 keer trainen? Pak die week het 2-daags schema.
    <br/><br/>
    <b>Gegarandeerde resultaten</b>.
  `}
/>

<TextImage
  bg="#fff"
  image={'/img/offer/offer-gids.jpg'}
  subtitle="Educatie"
  title="Ultieme Spiermassa Gids"
  content={`
    Geen uitgebreide e-book van 150 pagina's die je toch niet gaat lezen...
    <br/><br/>
    Maar een korte gids van een aantal pagina's met de <b>allerbelangrijkste informatie</b> die je nodig hebt over voeding, training, rust en herstel voor optimale spiergroei.
    <br/><br/>
    Ik heb alle informatie hierin geschreven die ik heb geleerd in de afgelopen jaren bij het trainen van honderden mannen.
  `}
/>

<TextImage
  image={'/img/page-header/stef-ridder-img.png'}
  subtitle="Coaching"
  title="Contact met een expert"
  content={`
    Kom je er gedurende de challenge niet uit? Loop je ergens vast of wil je meer informatie?
    <br/><br/>
    Je krijgt een rechtstreekse verbinding met je online personal coach, waarbij één tot twee keer per dag dagelijks al je vragen beantwoordt worden via de mail.
    <br/><br/>
    Geen reden meer om niet te groeien!
  `}
/> */}

{/* <TextImage
  subtitle="Voeding"
  title="Simpele methode om te eten voor spiergroei"
  content={`
    Ik snap dat je helemaal geen zin hebt om alles bij te houden wat je in je mond stopt...
    <br/><br/>
    Geen gedoe met calorieën tellen in deze challenge.
    <br/><br/>
    Je krijgt geleerd hoe je met <b>hele simpele</b> aanpassingen en <b>zonder hoge kosten</b> ervoor zorgt dat je je spieren kunt voorzien van voedsel voor optimale spiergroei.
    <br/><br/>
    Je kunt zelfs met het avondeten mee blijven eten.
  `}
/> */}

{/* <WatNodig /> */}

{/* <JouwCoach /> */}

{/* <NietVoorJou /> */}

{/* <ReviewsList /> */}

{/* <WatMoetIkLaten /> */}

{/* <Twijfels /> */}

      {/* <Traject /> */}

      {/* <Gifts /> */}

      {/* <OfferPakket defaultPrice={defaultPrice} /> */}

      {/* <section className="faq-section">
        <div className="container">
          <div className="row">
            <div className="col-md-10 mx-auto text-center mb-5">
              <h2>Herken jij jezelf in het volgende?</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-9 mx-auto">
              {
                objectionHandlers.map((faq, index) => (
                  <div key={index} className={`faq-item ${activeObjection == index ? 'active' : ''}`}>
                    <div className="faq-heading" onClick={() => setActiveObjection(index)}>
                      <h3>{ faq.vraag }</h3>
                      <svg width="283" height="141" viewBox="0 0 283 141" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M141.36 140.216C129.954 140.216 118.548 135.817 109.913 127.181L3.67769 20.9466C-1.04751 16.2214 -1.04751 8.40044 3.67769 3.67524C8.40288 -1.04995 16.2239 -1.04995 20.9491 3.67524L127.184 109.91C135.005 117.731 147.714 117.731 155.535 109.91L261.771 3.67524C266.496 -1.04995 274.317 -1.04995 279.042 3.67524C283.767 8.40044 283.767 16.2214 279.042 20.9466L172.807 127.181C164.171 135.817 152.765 140.216 141.36 140.216Z" fill="white"/>
                      </svg>
                    </div>
                    <div className="faq-body">
                      <p>
                        { faq.antwoord }
                      </p>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-12 d-flex justify-content-center">
              
              <a href="#offer" className="button button-white d-flex mt-4 justify-content-center">
                Bekijk aanbieding
              </a>
            </div>
          </div>
        </div>
      </section>  */}

      {/* <FeaturedReview /> */}

      {/* <JouwCoach /> */}

      {/* <DeApp /> */}

{/* <OfferCard defaultPrice={14.99} /> */}


{/* <Garantie /> */}

{/* <div class="container pb-[20px] md:pb-[50px] pt-[90px] pb-[90px] mx-auto px-4 flex flex-wrap md:flex-row sm:items-center md:items-stretch section-padding">
  <div class="w-full md:w-2/3 bg-primary-red text-white px-[30px] py-[50px] md:py-[60px] md:px-[50px] flex flex-col justify-between sm:order-2 md:order-1">
    <p class="text-3xl font-bold text-white sm:hidden md:block">100% Tevredenheidsgarantie</p>
    <h2 class="text-subheader3 md:text-h3 mt-2 text-neutral-white" style={{ lineHeight: '26px' }}>Ik ben hier om je te helpen. Wanneer jij, om welke reden dan ook, vindt dat je geen waarde van mij hebt ontvangen, dan zul je van mij altijd je geld terug ontvangen. Er zijn geen verborgen voorwaarden.</h2>
    <div class="flex justify-start">
      <a 
        href="#offer" 
        className="bg-white text-black px-6 py-3 rounded-[10px] font-bold flex space-x-2 items-center mt-5"
        title="Shop Now"
      >
        <span>Start vandaag</span>
        <div class="relative top-[2px]">
        </div>
      </a>
    </div>
  </div>
  <div class="w-full md:w-1/3 relative sm:mt-4 md:mt-0 sm:order-1 md:order-2 hidden md:block">
    <img src="/img/website/stef-deadlift.jpg" alt="Exclusive Summer Collection" class="w-full sm:h-[175px] md:h-full object-cover sm:relative md:absolute inset-0" style={{ objectPosition: '0 -150px' }} />
  </div>
</div>  */}

{/* <WatJeKrijgt /> */}

      {/* <section className="faq-section voorwie-section">
        <div className="container">
          <div className="row">
            <div className="col-md-10 mx-auto text-center mb-5">
              <h2>Wat ons eerder is gevraagd 👇</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-9 mx-auto">
              {
                faqs.map((faq, index) => (
                  <div key={index} className={`faq-item ${activeFaq == index ? 'active' : ''}`}>
                    <div className="faq-heading" onClick={() => setActiveFaq(index)}>
                      <h3>{ faq.vraag }</h3>
                      <svg width="283" height="141" viewBox="0 0 283 141" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M141.36 140.216C129.954 140.216 118.548 135.817 109.913 127.181L3.67769 20.9466C-1.04751 16.2214 -1.04751 8.40044 3.67769 3.67524C8.40288 -1.04995 16.2239 -1.04995 20.9491 3.67524L127.184 109.91C135.005 117.731 147.714 117.731 155.535 109.91L261.771 3.67524C266.496 -1.04995 274.317 -1.04995 279.042 3.67524C283.767 8.40044 283.767 16.2214 279.042 20.9466L172.807 127.181C164.171 135.817 152.765 140.216 141.36 140.216Z" fill="#000"/>
                      </svg>
                    </div>
                    <div className="faq-body">
                      <p>
                        { faq.antwoord }
                      </p>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-12 d-flex justify-content-center">
              
              <a href="#offer" className="button button-red d-flex mt-4 justify-content-center">
                Start vandaag
              </a>
            </div>
          </div>
        </div>
      </section>  */}

{/* <JouwCoach /> */}

{/* <Insta /> */}
  </>
  )
}

export default GoedeVoornemens;